import { LoadingButton } from '@mui/lab'
import { Button, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import { useGetEvent } from 'api/hooks/get'
import { usePatchEvent } from 'api/hooks/patch'
import { usePostEvent } from 'api/hooks/post'
import Flex from 'components/common/Flex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useAuth } from 'hooks/useAuth'
import { DatePicker, TextEditor, TextField, TimePicker } from 'inputs/ControlledInputs'
import { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserName, getUTCDateTime } from 'utils'

// Initialize dayjs plugins
dayjs.extend(utc)
dayjs.extend(timezone)

const timezones = [
  { value: 'America/New_York', label: 'America/New York' },
  { value: 'America/Chicago', label: 'America/Chicago' },
  { value: 'America/Denver', label: 'America/Denver' },
  { value: 'America/Los_Angeles', label: 'America/Los Angeles' },
  { value: 'America/Anchorage', label: 'America/Anchorage' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
  { value: 'Europe/London', label: 'Europe/London' },
  { value: 'Europe/Paris', label: 'Europe/Paris' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin' },
  { value: 'Europe/Moscow', label: 'Europe/Moscow' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland' },
]

const getUserTimezone = () => {
  try {
    return dayjs.tz.guess()
  } catch (error) {
    console.error('Error getting user timezone:', error)
    return 'America/New_York' // Fallback to ET
  }
}

const formatTimezoneLabel = (timezone) => {
  try {
    const offset = dayjs.tz(timezone).format('Z')
    const sign = offset.startsWith('-') ? '-' : '+'
    const hours = offset.slice(1, 3)
    const minutes = offset.slice(3, 5)
    return `${timezone.replace(/_/g, ' ')} (UTC${sign}${hours}:${minutes})`
  } catch (error) {
    return timezone.replace(/_/g, ' ')
  }
}

const EventCreateEdit = () => {
  const { pathname } = useLocation()
  const isCreate = pathname.substring(pathname.lastIndexOf('/') + 1) === 'create'
  const { user } = useAuth()
  const fetchEvent = useGetEvent(!isCreate)
  const event = fetchEvent.data
  const isHost = event?.hostId === user.id
  const navigate = useNavigate()
  const [showEndDateTime, setShowEndDateTime] = useState(!!event?.end_date_time)
  const [showTimezone, setShowTimezone] = useState(!!event?.timezone)
  const [userTimezone, setUserTimezone] = useState(getUserTimezone())

  if (!isCreate && !isHost) {
    navigate(`/events/${event.id}`)
    return null
  }

  const editorRef = useRef(null)
  const { mutateAsync: patchEvent } = usePatchEvent()
  const { mutateAsync: postEvent } = usePostEvent()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: event.title ?? '',
      host: event.host ?? getUserName(user),
      location: event.location ?? '',
      max_attendees: event.max_attendees ?? 25,
      start_date: event.start_date_time ? dayjs(event.start_date_time) : null,
      start_time: event.start_date_time ? dayjs(event.start_date_time) : null,
      end_date: event.end_date_time ? dayjs(event.end_date_time) : null,
      end_time: event.end_date_time ? dayjs(event.end_date_time) : null,
      timezone: event.timezone ?? userTimezone,
      description: event.description ?? null,
    },
  })

  // Watch the timezone value to update userTimezone when it changes
  const currentTimezone = watch('timezone')
  useEffect(() => {
    if (currentTimezone) {
      setUserTimezone(currentTimezone)
    }
  }, [currentTimezone])

  const onSubmit = async (formValues) => {
    const hasEndDate = formValues.end_date
    const event = {
      title: formValues.title,
      start_date_time: getUTCDateTime(formValues.start_date, formValues.start_time),
      end_date_time: hasEndDate
        ? getUTCDateTime(formValues.end_date, formValues.end_time ?? formValues.end_date)
        : undefined,
      host: formValues.host,
      location: formValues.location,
      max_attendees: Number(formValues.max_attendees),
      time_zone: showTimezone ? formValues.timezone : undefined,
      description: JSON.stringify(editorRef.current.getEditorState()),
    }

    isCreate ? await postEvent(event) : await patchEvent(event)
  }

  const handleToggleEndDateTime = () => {
    setShowEndDateTime(!showEndDateTime)
    if (!showEndDateTime) {
      setValue('end_date', watch('start_date'))
      setValue('end_time', watch('start_time'))
    } else {
      setValue('end_date', null)
      setValue('end_time', null)
    }
  }

  const handleToggleTimezone = () => {
    setShowTimezone(!showTimezone)
    if (!showTimezone) {
      setValue('timezone', userTimezone)
    }
  }

  return (
    <Flex component="form" sx={style.container} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" alignSelf="center" mb="16px">
        {isCreate ? 'Plan an' : 'Edit your'} event
      </Typography>
      <TextField
        control={control}
        name="title"
        label="Event name"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
      />
      <TextField
        control={control}
        name="host"
        label="Hosted by"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
      />
      <TextField
        control={control}
        name="location"
        label="Location"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
      />
      <TextField
        control={control}
        name="max_attendees"
        label="Max attendees"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
        type="number"
      />
      <Flex row gap="8px" alignItems="flex-start">
        <DatePicker
          control={control}
          name="start_date"
          label="Start date"
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
        />
        <TimePicker
          control={control}
          name="start_time"
          label="Start time"
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
        />
      </Flex>
      <Flex column gap="8px">
        {showEndDateTime && (
          <Flex row gap="8px" alignItems="flex-start">
            <DatePicker
              control={control}
              name="end_date"
              label="End date"
              disabled={isSubmitting}
              referenceDate={watch('start_date')}
            />
            <TimePicker
              control={control}
              name="end_time"
              label="End time"
              disabled={isSubmitting}
            />
          </Flex>
        )}
        <Flex row justifyContent="flex-end">
          <Button variant="text" onClick={handleToggleEndDateTime} sx={{ alignSelf: 'flex-end' }}>
            {showEndDateTime ? '- Remove end date/time' : '+ Add end date/time'}
          </Button>
        </Flex>
      </Flex>
      <TextEditor
        control={control}
        name="description"
        placeholder="Event description"
        disabled={isSubmitting}
        editorRef={editorRef}
        rules={{ required: 'Required' }}
      />
      <Flex column gap="8px">
        {showTimezone && (
          <FormControl fullWidth>
            <InputLabel>Timezone</InputLabel>
            <Select
              value={watch('timezone')}
              onChange={(e) => setValue('timezone', e.target.value)}
              label="Timezone"
              disabled={isSubmitting}
            >
              {timezones.map((tz) => (
                <MenuItem key={tz.value} value={tz.value}>
                  {formatTimezoneLabel(tz.value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Flex row justifyContent="flex-end">
          <Typography variant="caption" color="text.secondary">
            {showTimezone ? (
              <>
                The timezone for this event is {formatTimezoneLabel(watch('timezone'))}.{' '}
                <Typography
                  component="span"
                  variant="caption"
                  color="primary"
                  sx={{
                    fontStyle: 'italic',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={handleToggleTimezone}
                >
                  Set timezone
                </Typography>
              </>
            ) : (
              <>
                The timezone for this event is set to {formatTimezoneLabel(watch('timezone'))}.{' '}
                <Typography
                  component="span"
                  variant="caption"
                  color="primary"
                  sx={{
                    fontStyle: 'italic',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={handleToggleTimezone}
                >
                  Click here to change the timezone
                </Typography>
              </>
            )}
          </Typography>
        </Flex>
      </Flex>
      <Flex row alignSelf="center" gap="16px">
        {!isCreate && (
          <Button variant="text" onClick={() => navigate(-1)} disabled={isSubmitting}>
            Cancel
          </Button>
        )}
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {isCreate ? 'Create event' : 'Confirm'}
        </LoadingButton>
      </Flex>
    </Flex>
  )
}

export default EventCreateEdit

const style = {
  container: {
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto',
    padding: ['16px', '32px'],
    gap: '16px',
  },
}
