/**
 * Extracts dominant colors from an image and generates a gradient
 * @param {string} imageUrl - URL of the image to analyze
 * @returns {Promise<string>} CSS gradient string
 */
export const generateGradientFromImage = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    console.log('Starting gradient generation for:', imageUrl)

    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.credentials = 'include'

    img.onload = () => {
      try {
        console.log('Image loaded successfully')
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height

        ctx.drawImage(img, 0, 0)

        // Sample colors from different regions of the image
        const regions = [
          { x: 0, y: 0, width: img.width / 2, height: img.height / 2 }, // Top left
          { x: img.width / 2, y: 0, width: img.width / 2, height: img.height / 2 }, // Top right
          { x: 0, y: img.height / 2, width: img.width / 2, height: img.height / 2 }, // Bottom left
          { x: img.width / 2, y: img.height / 2, width: img.width / 2, height: img.height / 2 }, // Bottom right
        ]

        const colors = regions.map((region) => {
          try {
            const imageData = ctx.getImageData(region.x, region.y, region.width, region.height)
            const data = imageData.data
            let r = 0,
              g = 0,
              b = 0,
              count = 0

            // Average the colors in each region
            for (let i = 0; i < data.length; i += 4) {
              r += data[i]
              g += data[i + 1]
              b += data[i + 2]
              count++
            }

            // Calculate average RGB values
            r = Math.round(r / count)
            g = Math.round(g / count)
            b = Math.round(b / count)

            // Ensure minimum brightness by adjusting RGB values
            const minBrightness = 0.9 // Minimum brightness threshold (0-1)
            const currentBrightness = (r + g + b) / (255 * 3)

            if (currentBrightness < minBrightness) {
              const factor = minBrightness / currentBrightness
              r = Math.min(255, Math.round(r * factor))
              g = Math.min(255, Math.round(g * factor))
              b = Math.min(255, Math.round(b * factor))
            }

            const color = {
              rgb: `rgb(${r}, ${g}, ${b})`,
              brightness: (r + g + b) / (255 * 3),
            }
            console.log('Region color:', color)
            return color
          } catch (error) {
            console.error('Error processing region:', error)
            return { rgb: 'rgb(245, 245, 245)', brightness: 0.96 } // Fallback to light gray
          }
        })

        // Sort colors by brightness (darkest to lightest)
        colors.sort((a, b) => a.brightness - b.brightness)

        // Take the darkest colors and add white at the bottom
        const darkestColors = colors.slice(0, 3) // Take top 3 darkest colors
        const gradientColors = [...darkestColors.map((c) => c.rgb), '#ffffff']

        // Create gradient using the sorted colors, fading to white at 50%
        const gradient = `linear-gradient(to bottom, ${gradientColors
          .map((color, index) => {
            if (index === gradientColors.length - 1) {
              return `${color} 70%`
            }
            return color
          })
          .join(', ')})`
        console.log('Generated gradient:', gradient)
        resolve(gradient)
      } catch (error) {
        console.error('Error in image processing:', error)
        reject(error)
      }
    }

    img.onerror = (error) => {
      console.error('Error loading image:', error)
      // Fallback to a default gradient if image loading fails
      resolve('linear-gradient(to bottom, #f5f5f5, #ffffff)')
    }

    // Add a timestamp to prevent caching
    const timestamp = new Date().getTime()
    const urlWithTimestamp = `${imageUrl}?t=${timestamp}`
    img.src = urlWithTimestamp
  })
}
